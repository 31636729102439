<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-card class="pa-4">
            <v-toolbar flat dense color="white">
                <v-toolbar-title>Registration Policy</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
            <v-row>
                <v-col cols="12" sm="3" md="3">
                    <v-text-field v-model="templatename" label="Name*" type="text" outlined></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <ckeditor v-model="templatebody" :config="editorConfig" style="margin: 8px;" class="pt-0 pb-0">
                    </ckeditor>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="10" md="10">
                    <v-file-input id="attachment_id" outlined label="Attachment" prepend-icon="mdi-file" show-size
                        v-model="file"></v-file-input>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                    <v-icon v-if="url" class="green--text mt-5" @click="open_file()">mdi mdi-eye</v-icon>
                    </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" sm="1" md="1">
                    <v-btn @click="savepolicy()" class="primary">
                        SAVE
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>

import axios from "axios";
export default {
    data: () => ({
        editorConfig: {
            extraPlugins: "mathjax",
            extraPlugins: "colorbutton",
            mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS_HTML",
        },

        color: "",
        snackbar_msg: "",
        snackbar: "",
        templatename: "",
        templatebody: "",
        save_loading: "",
        file: null,
        url:""
    }),


    methods: {
        open_file()
        {
            window.open(this.url, '_blank');
        },
        init() {
            this.init_loading = true;
            axios
                .post("/AdminMaster/registration_policy",)

                .then((res) => {
                    if (res.data.msg == "200") {
                        this.init_loading = false;
                        this.templatebody = res.data.body;
                        this.templatename = res.data.name;
                        this.url=res.data.url;
                    }
                    else {
                        this.init_loading = false;
                        this.showSnackbar("red", res.data.msg);
                    }
                })
        },
      
        savepolicy() {
            this.save_loading = true;

            let formData = new FormData();
            formData.append("name", this.templatename);
            formData.append("body", this.templatebody);
            formData.append("file", this.file);
            axios
                .post("/AdminMaster/savepolicy", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })

                .then((res) => {
                    if (res.data.msg == "200") {
                        this.save_loading = false;
                        this.showSnackbar("green", "Successfully Save.");
                    }
                    else {
                        this.save_loading = false;
                        this.showSnackbar("red", res.data.msg);
                    }
                })

        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },






    },
    mounted() {

        this.init();
    },
};
</script>
<style scoped>
.ec-title {
    background-image: linear-gradient(-100deg, #f4faba, #d64907);
    color: #fff;
}

.c-title {
    background-image: linear-gradient(-90deg, #befcf7, #03948d);
    color: #fff;
}

.tc-title {
    background-image: linear-gradient(-90deg, skyblue, #057996);
    color: #fff;
    border-radius: 3px;
}

.logi {
    background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
    color: #fff;
}

.card-title {
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #fff;
}

.lbl {
    color: #1705e6;
}

.c-title1 {
    background-image: linear-gradient(-90deg, #dbdbdb, #012944);
    color: #fff;
}

.link-icon {
    color: #fff;
}

.logi2 {
    background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>